import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { FaList, FaBookOpen } from "react-icons/fa"; // Import only necessary icons

const WhatsAppChat = () => {
  const [activeTooltip, setActiveTooltip] = useState("Price List"); // Track which tooltip is active

  // Button configurations with only "Price List" and "Catalog"
  const buttons = [
    {
      name: "Price List",
      link: "https://pricelist.wellmepipes.com/",
      icon: <FaList className="text-lg" />,
      bgColor: "bg-gradient-to-r from-blue-600 to-blue-400",
    },
  ];

  // Cycle tooltips every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTooltip((prev) =>
        prev === "Price List" ? "Catalog" : "Price List"
      );
    }, 3000);

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  return (
    <div className="fixed bottom-2 left-4 flex flex-col items-center space-y-2">
      {buttons.map((button) => (
        <div key={button.name} className="relative">
          {/* Tooltip for button */}
          <motion.div
            className="absolute right-full mr-2 text-center text-white bg-black rounded-md p-3 overflow-hidden shadow-md"
            initial={{ width: 0, opacity: 0 }}
            animate={{
              width: activeTooltip === button.name ? "6rem" : 0,
              opacity: activeTooltip === button.name ? 1 : 0,
            }}
            transition={{ duration: 0.5 }}
            style={{
              whiteSpace: "nowrap", // Prevents text wrapping
              clipPath: "inset(0 50% 0 0)", // Initially clips half of the text
            }}
          >
            {button.name}
          </motion.div>
          <a
            href={button.link}
            target="_blank"
            rel="noopener noreferrer"
            className={`flex items-center justify-center w-12 h-12 rounded-full ${button.bgColor} text-white shadow-lg transform transition-transform duration-300 hover:scale-110 hover:shadow-xl`}
            style={{
              boxShadow:
                "5px 5px 10px rgba(0, 0, 0, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.2)",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
            }}
          >
            {button.icon}
          </a>
        </div>
      ))}
    </div>
  );
};

export default WhatsAppChat;
