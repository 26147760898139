import React, { useState, useEffect } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { motion, AnimatePresence } from "framer-motion";
import About from "./About";
import YouTubeVideoCarousel from "./YouTubeVideoCarousel";

const PrimeX = () => {
  const [productData, setProductData] = useState(null);
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [loading, setLoading] = useState(true);

  const [currentColorIndex, setCurrentColorIndex] = useState(0);
  const [currentImages, setCurrentImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showFullScreen, setShowFullScreen] = useState(false); // Full-screen modal state
  const [expandedImageSrc, setExpandedImageSrc] = useState(""); // Source for expanded image

  // Fetch Product by ID
  const fetchProductById = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/66fe79ff7e58278e590878c8`
      );
      setProductData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching product data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductById();
  }, []);

  // Organize product data into models, sizes, and colors
  useEffect(() => {
    if (productData && productData.products?.length > 0) {
      const modelOptions = productData.products.map((product) => ({
        model: product.title,
        description: product.description,
        ytLinks: productData?.ytLinks ?? [],
        sizes: product?.sizes ?? {},
      }));

      setModels(modelOptions);
      setSelectedModel(modelOptions[0] ?? null);
      const initialSize = modelOptions[0]?.sizes
        ? Object.keys(modelOptions[0].sizes)[0]
        : null;
      setSelectedSize(initialSize);
      if (initialSize) {
        setCurrentImages(
          modelOptions[0]?.sizes[initialSize]?.colors?.["white"] ?? []
        );
      }
    }
  }, [productData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (selectedModel && selectedSize) {
      const colors =
        selectedModel?.sizes?.[selectedSize]?.colors ??
        selectedModel?.sizes?.[Object.keys(selectedModel?.sizes)[0]]?.colors;

      const colorKeys = colors ? Object.keys(colors) : [];
      const totalColors = colorKeys.length;
      const totalImages = colors
        ? colors[colorKeys[currentColorIndex]]?.length ?? 0
        : 0;

      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => {
          if (prevIndex + 1 < totalImages) {
            return prevIndex + 1;
          } else {
            setCurrentColorIndex(
              (prevColorIndex) => (prevColorIndex + 1) % totalColors
            );
            setCurrentImages(
              colors
                ? colors[colorKeys[(currentColorIndex + 1) % totalColors]]
                : []
            );
            return 0;
          }
        });
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [currentColorIndex, selectedModel, selectedSize]);

  const handleColorChange = (color) => {
    if (
      selectedModel?.sizes?.[selectedSize]?.colors &&
      selectedModel.sizes[selectedSize].colors[color]
    ) {
      setCurrentImages(selectedModel.sizes[selectedSize].colors[color]);
      setCurrentColorIndex(
        Object.keys(selectedModel.sizes[selectedSize].colors).indexOf(color)
      );
      setCurrentImageIndex(0);
    }
  };

  const handleModelChange = (modelTitle) => {
    const selected = models.find((model) => model.model === modelTitle);
    setSelectedModel(selected);
    const initialSize = selected?.sizes ? Object.keys(selected.sizes)[0] : null;
    setSelectedSize(initialSize);
    if (initialSize) {
      setCurrentImages(selected?.sizes[initialSize]?.colors?.["white"] ?? []);
    }
    setCurrentColorIndex(0);
    setCurrentImageIndex(0);
  };

  const handleSizeChange = (sizeTitle) => {
    setSelectedSize(sizeTitle);
    setCurrentImages(
      selectedModel?.sizes?.[sizeTitle]?.colors?.["white"] ?? []
    );
    setCurrentColorIndex(0);
    setCurrentImageIndex(0);
  };

  const openFullScreen = () => {
    setExpandedImageSrc(currentImages[currentImageIndex]);
    setShowFullScreen(true);
  };

  return (
    <div className="mx-auto px-2 py-5 max-w-6xl">
      {/* Full-Screen Modal for Expanded Image */}
      <AnimatePresence>
        {showFullScreen && (
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="relative space-y-5">
              <button
                onClick={() => setShowFullScreen(false)}
                className="absolute top-5 right-3 text-black text-4xl focus:outline-none"
              >
                &times;
              </button>
              <img
                src={expandedImageSrc}
                alt="Full Screen Product"
                className="w-full h-auto bg-white max-w-screen-lg max-h-screen-lg object-contain"
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Breadcrumb Navigation */}
      <nav className="flex mb-4" aria-label="Breadcrumb">
        <ol
          id="award"
          className="inline-flex items-center space-x-1 md:space-x-3 text-[10px] font-mono text-black"
        >
          <li>
            <a
              href="/"
              className="font-semibold text-black hover:text-gray-600 transition-colors duration-200"
            >
              Home
            </a>
          </li>
          <li className="font-light text-gray-500">/</li>
          <li className="font-semibold text-black hover:text-gray-600 transition-colors duration-200">
            {loading ? <Skeleton width={100} /> : productData?.category}
          </li>
          <li className="font-light text-gray-500">/</li>
          <li className="font-semibold text-black hover:text-gray-600 transition-colors duration-200">
            Tape
          </li>
        </ol>
      </nav>

      {/* Product Details */}
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2 mb-4 md:mb-0">
          {/* Main Big Image */}
          <div className="flex justify-center mb-4">
            {loading ? (
              <Skeleton height={300} width={300} />
            ) : (
              <motion.img
                src={currentImages[currentImageIndex] || ""}
                alt="Selected Product"
                className="w-full h-auto max-h-96 object-contain rounded-md cursor-pointer"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                onClick={openFullScreen} // Click to expand image
              />
            )}
          </div>
          <div className="flex justify-center items-center mt-4 space-x-2">
            {loading
              ? [...Array(3)].map((_, index) => (
                  <Skeleton key={index} width={80} height={60} />
                ))
              : currentImages?.map((image, index) => (
                  <motion.img
                    key={index}
                    src={image}
                    alt={`Product Image ${index + 1}`}
                    className={`w-15 h-16 rounded-md cursor-pointer transition-transform transform ${
                      currentImageIndex === index
                        ? "border-2 border-customBlue scale-105"
                        : "border border-gray-300 hover:scale-105"
                    }`}
                    onClick={() => setCurrentImageIndex(index)} // Set main image on click
                    whileHover={{ scale: 1.1 }}
                  />
                ))}
          </div>
        </div>

        <div className="md:w-1/2 md:pl-8">
          {/* Product Title and Description */}
          <h1 id="award" className="text-xl font-bold font-mono  text-black">
            {loading ? <Skeleton width={200} /> : selectedModel?.model}
          </h1>

          {/* Model Selection */}
          <div className="mt-4">
            <h2 className="text-lg font-bold font-mono mb-2 text-black">
              Select Model:
            </h2>
            <ul className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {loading
                ? [...Array(2)].map((_, index) => (
                    <li key={index}>
                      <Skeleton width={100} height={40} />
                    </li>
                  ))
                : models?.map((model) => (
                    <li key={model.model}>
                      <button
                        className={`w-full py-2 px-3 rounded-md border text-center transition-all ${
                          selectedModel?.model === model.model
                            ? "bg-customBlue text-white"
                            : "bg-white text-black border-gray-300 hover:bg-gray-100"
                        }`}
                        onClick={() => handleModelChange(model.model)}
                      >
                        {model.model}
                      </button>
                    </li>
                  ))}
            </ul>
          </div>

          {/* Size Selection */}
          <div className="mt-4">
            <h2 className="text-lg font-bold font-mono mb-2 text-black">
              Select Size:
            </h2>
            <ul className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {loading
                ? [...Array(3)].map((_, index) => (
                    <li key={index}>
                      <Skeleton width={100} height={40} />
                    </li>
                  ))
                : selectedModel?.sizes &&
                  Object.keys(selectedModel.sizes).map((size) => (
                    <li key={size}>
                      <button
                        className={`w-full py-2 px-3 rounded-md border text-center transition-all ${
                          selectedSize === size
                            ? "bg-customBlue text-white"
                            : "bg-white text-black border-gray-300 hover:bg-gray-100"
                        }`}
                        onClick={() => handleSizeChange(size)}
                      >
                        {size} MTR
                      </button>
                    </li>
                  ))}
            </ul>
          </div>

          {/* Color Selection */}
          <div className="mt-4">
            <h2 className="text-lg font-bold font-mono mb-2 text-black">
              Select Color:
            </h2>
            <ul className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {loading
                ? [...Array(3)].map((_, index) => (
                    <li key={index}>
                      <Skeleton width={100} height={40} />
                    </li>
                  ))
                : selectedModel?.sizes?.[selectedSize]?.colors &&
                  Object.keys(selectedModel?.sizes?.[selectedSize]?.colors).map(
                    (color) => (
                      <button
                        key={color}
                        className={`p-2 border rounded ${
                          currentColorIndex ===
                          Object.keys(
                            selectedModel?.sizes?.[selectedSize]?.colors
                          ).indexOf(color)
                            ? "bg-customBlue text-white"
                            : "bg-white text-black"
                        }`}
                        onClick={() => handleColorChange(color)}
                      >
                        {color.charAt(0).toUpperCase() + color.slice(1)}
                      </button>
                    )
                  )}
            </ul>
          </div>

          {/* Description */}
          <h2
            id="award"
            className="text-sm font-bold font-mono mt-4 mb-2 text-black"
          >
            Description
          </h2>
          <p className="text-gray-700 text-sm">
            {loading ? <Skeleton count={3} /> : selectedModel?.description}
          </p>
        </div>
      </div>

      <div className=" mt-4">
        <About />
      </div>

      <YouTubeVideoCarousel
        ytLinks={
          productData?.ytLinks ? productData.ytLinks.map((yt) => yt.link) : []
        }
      />
    </div>
  );
};

export default PrimeX;
