import React, { useState, useEffect } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { motion, AnimatePresence } from "framer-motion";
import About from "./About";
import YouTubeVideoCarousel from "./YouTubeVideoCarousel";

const PrimeX = () => {
  const [productData, setProductData] = useState({});
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState({});
  const [sizes, setSizes] = useState([]);
  const [selectedSize, setSelectedSize] = useState({});
  const [loading, setLoading] = useState(true);

  const [currentColorIndex, setCurrentColorIndex] = useState(0);
  const [currentImages, setCurrentImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [expandedImageSrc, setExpandedImageSrc] = useState("");

  const fetchProductById = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/66fa891726a786dcf66e88d6`
      );
      setProductData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching product data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductById();
  }, []);

  useEffect(() => {
    if (productData?.products?.length > 0) {
      setModels(productData.products);
      setSelectedModel(productData.products[0]);
    }
  }, [productData]);

  useEffect(() => {
    if (selectedModel && selectedModel.sizes) {
      const newSizes = Object.keys(selectedModel.sizes).map((sizeKey) => {
        const size = selectedModel.sizes[sizeKey];
        return {
          title: sizeKey,
          colors: size.colors,
        };
      });

      // Sort sizes to show "3.25" and "4" first, followed by other sizes
      const sortedSizes = newSizes.sort((a, b) => {
        if (a.title === "3.25") return -1;
        if (b.title === "3.25") return 1;
        if (a.title === "4") return -1;
        if (b.title === "4") return 1;
        return 0;
      });

      setSizes(sortedSizes);
      setSelectedSize(sortedSizes[0]);
      setCurrentImages(sortedSizes[0]?.colors["white"] || []);
    }
  }, [selectedModel]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (selectedSize?.colors) {
      const colorKeys = Object.keys(selectedSize.colors);
      const totalColors = colorKeys.length;
      const totalImages =
        selectedSize.colors[colorKeys[currentColorIndex]].length;

      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => {
          if (prevIndex + 1 < totalImages) {
            return prevIndex + 1;
          } else {
            setCurrentColorIndex(
              (prevColorIndex) => (prevColorIndex + 1) % totalColors
            );
            setCurrentImages(
              selectedSize.colors[
                colorKeys[(currentColorIndex + 1) % totalColors]
              ]
            );
            return 0;
          }
        });
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [currentColorIndex, selectedSize]);

  const handleModelChange = (modelTitle) => {
    const selected = models.find((model) => model.title === modelTitle);
    setSelectedModel(selected);
  };

  const handleSizeChange = (sizeTitle) => {
    const selected = sizes.find((size) => size.title === sizeTitle);
    setSelectedSize(selected);
    setCurrentImages(selected.colors["white"] || []);
    setCurrentColorIndex(0);
    setCurrentImageIndex(0);
  };

  const handleColorChange = (color) => {
    if (selectedSize.colors && selectedSize.colors[color]) {
      setCurrentImages(selectedSize.colors[color]);
      setCurrentColorIndex(Object.keys(selectedSize.colors).indexOf(color));
      setCurrentImageIndex(0);
    }
  };

  const openFullScreen = () => {
    setExpandedImageSrc(currentImages[currentImageIndex]);
    setShowFullScreen(true);
  };

  return (
    <div className="mx-auto px-2 py-5 max-w-6xl">
      {/* Full-Screen Modal for Expanded Image */}
      <AnimatePresence>
        {showFullScreen && (
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="relative space-y-5">
              <button
                onClick={() => setShowFullScreen(false)}
                className="absolute top-5 right-3 text-black text-4xl     focus:outline-none"
              >
                &times;
              </button>
              <img
                src={expandedImageSrc}
                alt="Full Screen Product"
                className="w-full h-auto max-w-screen-lg max-h-screen-lg object-cover"
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Breadcrumb Navigation */}
      <nav className="flex mb-4" aria-label="Breadcrumb">
        <ol
          id="award"
          className="inline-flex items-center space-x-1 md:space-x-3 text-[10px] font-mono text-black"
        >
          <li>
            <a
              href="/"
              className="font-semibold text-black hover:text-gray-600 transition-colors duration-200"
            >
              Home
            </a>
          </li>
          <li className="font-light text-gray-500">/</li>
          <li className="font-semibold text-black hover:text-gray-600 transition-colors duration-200">
            {loading ? <Skeleton width={100} /> : productData?.category}
          </li>
          <li className="font-light text-gray-500">/</li>
          <li className="font-semibold text-black hover:text-gray-600 transition-colors duration-200">
            Round Sheet
          </li>
        </ol>
      </nav>

      {/* Product Details */}
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2 mb-4 md:mb-0">
          {/* Main Big Image */}
          <div className="flex justify-center mb-4">
            {loading ? (
              <Skeleton height={300} width={300} />
            ) : (
              <img
                src={currentImages[currentImageIndex] || ""}
                alt="Selected Product"
                className="w-full h-auto max-h-96 object-contain rounded-md cursor-pointer"
                onClick={openFullScreen} // Click to expand image
              />
            )}
          </div>
          <div className="flex justify-center items-center mt-4 space-x-2">
            {loading
              ? [...Array(3)].map((_, index) => (
                  <Skeleton key={index} width={80} height={60} />
                ))
              : currentImages.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Product Image ${index + 1}`}
                    className={`w-15 h-16 rounded-md cursor-pointer transition-transform transform ${
                      currentImageIndex === index
                        ? "border-2 border-customBlue scale-105"
                        : "border border-gray-300 hover:scale-105"
                    }`}
                    onClick={() => setCurrentImageIndex(index)}
                  />
                ))}
          </div>
        </div>

        <div className="md:w-1/2 md:pl-8">
          <h1 id="award" className=" font-bold font-mono text-xl text-black">
            {loading ? <Skeleton width={200} /> : productData?.name}
          </h1>

          {/* Model Selection */}
          <div className="mt-4">
            <h2 className="text-lg font-bold font-mono mb-2 text-black">
              Select Model:
            </h2>
            <ul className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {loading
                ? [...Array(2)].map((_, index) => (
                    <li key={index}>
                      <Skeleton width={100} height={40} />
                    </li>
                  ))
                : models.map((model) => (
                    <li key={model.title}>
                      <button
                        className={`w-full py-2 px-3 rounded-md border text-center transition-all ${
                          selectedModel.title === model.title
                            ? " bg-customBlue text-white"
                            : "bg-white text-black border-gray-300 hover:bg-gray-100"
                        }`}
                        onClick={() => handleModelChange(model.title)}
                      >
                        {model.title}
                      </button>
                    </li>
                  ))}
            </ul>
          </div>

          {/* Size Selection */}
          <div className="mt-4">
            <h2 className="text-lg font-bold font-mono mb-2 text-black">
              Select Size:
            </h2>
            <ul className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {loading
                ? [...Array(2)].map((_, index) => (
                    <li key={index}>
                      <Skeleton width={100} height={40} />
                    </li>
                  ))
                : sizes.map((size) => (
                    <li key={size.title}>
                      <button
                        className={`w-full py-2 px-3 rounded-md border text-center transition-all ${
                          selectedSize.title === size.title
                            ? " bg-customBlue text-white"
                            : "bg-white text-black border-gray-300 hover:bg-gray-100"
                        }`}
                        onClick={() => handleSizeChange(size.title)}
                      >
                        {size.title} INCH
                      </button>
                    </li>
                  ))}
            </ul>
          </div>

          {/* Color Selection */}
          <div className="mt-4">
            <h2 className="text-lg font-bold font-mono mb-2 text-black">
              Select Color:
            </h2>
            <ul className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {loading
                ? [...Array(2)].map((_, index) => (
                    <li key={index}>
                      <Skeleton width={100} height={40} />
                    </li>
                  ))
                : selectedSize.colors &&
                  Object.keys(selectedSize.colors).map((color) => (
                    <button
                      key={color}
                      className={`p-2 border rounded ${
                        currentColorIndex ===
                        Object.keys(selectedSize.colors).indexOf(color)
                          ? "bg-customBlue text-white"
                          : "bg-white text-black"
                      }`}
                      onClick={() => handleColorChange(color)}
                    >
                      {color.charAt(0).toUpperCase() + color.slice(1)}
                    </button>
                  ))}
            </ul>
          </div>

          {/* Description */}
          <h2
            id="award"
            className="text-sm font-bold font-mono mt-4 mb-2 text-black"
          >
            Description
          </h2>
          <p className="text-gray-700 text-sm">
            {loading ? <Skeleton count={3} /> : selectedModel.description}
          </p>
        </div>
      </div>

      <div className="mt-4">
        <About />
      </div>

      {/* YouTube Video Section */}
      <YouTubeVideoCarousel
        ytLinks={
          productData?.ytLinks ? productData.ytLinks.map((yt) => yt.link) : []
        }
      />
    </div>
  );
};

export default PrimeX;
