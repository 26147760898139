import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Carousel from "../Components/Carousel";
import { motion } from "framer-motion";

const HomePage = () => {
  const featuredProducts = [
    {
      id: 1,
      name: "Deep Junction Box",
      url: "/other-products/deep-junction",
    },
    {
      id: 2,
      name: "Cable Clips",
      url: "/other-products/cable-clips",
    },
    {
      id: 3,
      name: "Double Nail Clamp",
      url: "/other-products/double-nail-clamp",
    },
    {
      id: 4,
      name: "Fanbox",
      url: "/other-products/fanbox",
    },
    {
      id: 5,
      name: "Fan Sheet",
      url: "/other-products/fan-sheet",
    },
    {
      id: 6,
      name: "Gitti",
      url: "/other-products/gitti",
    },
    {
      id: 7,
      name: "Junction Box",
      url: "/other-products/junction-box",
    },
    {
      id: 8,
      name: "Round Sheet",
      url: "/other-products/round-sheet",
    },
    {
      id: 9,
      name: "Square Box",
      url: "/other-products/square-box",
    },
    {
      id: 10,
      name: "Tape",
      url: "/other-products/tape",
    },
  ];

  const [videos, setVideos] = useState([]);
  const [screenType, setScreenType] = useState("Mobile");

  useEffect(() => {
    // Set screenType based on window width
    const updateScreenType = () => {
      setScreenType(window.innerWidth > 768 ? "Desktop" : "Mobile");
    };

    // Call once to set the initial state
    updateScreenType();

    // Add event listener to update screenType on resize
    window.addEventListener("resize", updateScreenType);

    return () => {
      window.removeEventListener("resize", updateScreenType);
    };
  }, []);

  useEffect(() => {
    // Fetch the videos from the API
    const fetchVideos = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/videos/all/`
        );
        setVideos(response.data);
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    fetchVideos();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Find the relevant video based on the screen type
  const productsVideo = videos.find(
    (video) =>
      video.title === "Other Products" && video.description === screenType
  );

  return (
    <div className="min-h-screen md:w-[85%] mx-auto bg-white font-roboto">
      {/* Full-screen video at the top */}
      <div>
        <Carousel videoUrl={productsVideo?.videoUrl} />
      </div>

      {/* Main content */}
      <main className="container mx-auto px-4 py-6">
        <section>
          <motion.h3
            className="text-3xl font-bold text-gray-800 mb-8 text-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            Featured Products
          </motion.h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {featuredProducts.map((product) => (
              <motion.div
                key={product.id}
                className="flex flex-col"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: product.id * 0.2 }} // Delay based on index for a staggered effect
              >
                <Link to={product.url} className="relative flex flex-col">
                  <motion.div
                    className="flex flex-col items-center justify-center rounded-lg shadow-lg transition duration-300 transform bg-customBlue text-white overflow-hidden cursor-pointer h-auto w-full"
                    whileHover={{ scale: 1.05 }} // Scale up on hover
                    whileTap={{ scale: 0.95 }} // Scale down on click
                  >
                    <div className="md:p-6 p-2 text-center">
                      <h4 className="md:text-4xl text-xl uppercase font-semibold">
                        {product.name}
                      </h4>
                    </div>
                  </motion.div>
                </Link>
              </motion.div>
            ))}
          </div>
        </section>
      </main>
    </div>
  );
};

export default HomePage;
