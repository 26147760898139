import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Carousel from "../Components/Carousel";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const HomePage = () => {
  const featuredProducts = [
    {
      id: 1,
      name: "Pipes",
      links: [
        {
          url: "/thunder/pipes/lms",
          title: "LMS",
          theme: "Light Mechanical Stress",
          bgColor: "rgb(148, 27, 30)", // Red
          textColor: "#FFFFFF", // White text for contrast
        },
        {
          url: "/thunder/pipes/mms",
          title: "MMS",
          theme: "Medium Mechanical Stress",
          bgColor: "rgb(43, 71, 119)", // Blue
          textColor: "#FFFFFF", // White text for contrast
        },
        {
          url: "/thunder/pipes/hms",
          title: "HMS",
          theme: "Heavy Mechanical Stress",
          bgColor: "rgb(56, 127, 116)", // Green
          textColor: "#FFFFFF", // White text for contrast
        },
        {
          url: "/thunder/pipes/sms",
          title: "SMS",
          theme: "Super Mechanical Stress",
          bgColor: "rgb(224, 178, 39)", // Yellow
          textColor: "#000000", // Black text for contrast
        },
      ],
    },
  ];

  const [videos, setVideos] = useState([]);
  const [screenType, setScreenType] = useState("Mobile");
  const [loading, setLoading] = useState(true);
  const productRefs = useRef([]);

  useEffect(() => {
    const updateScreenType = () => {
      setScreenType(window.innerWidth > 768 ? "Desktop" : "Mobile");
    };

    updateScreenType();
    window.addEventListener("resize", updateScreenType);

    return () => {
      window.removeEventListener("resize", updateScreenType);
    };
  }, []);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/videos/all/`
        );
        setVideos(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching videos:", error);
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("slide-in-visible");
          }
        });
      },
      { threshold: 0.2 }
    );

    productRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      productRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  const productsVideo = videos.find(
    (video) => video.title === "Thunder" && video.description === screenType
  );

  return (
    <div className="min-h-screen md:w-[85%] mx-auto bg-white font-roboto">
      {screenType === "Mobile" && (
        <div>
          {loading ? (
            <Skeleton height={400} width="100%" />
          ) : (
            productsVideo && <Carousel videoUrl={productsVideo.videoUrl} />
          )}
        </div>
      )}

      <main className="container mx-auto px-4 py-6">
        <section>
          <h3
            id="award"
            className="text-xl font-bold text-gray-800 mb-8 text-center"
          >
            Featured Products
          </h3>
          <div className="grid mb-3 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {featuredProducts.map((product, index) => (
              <div
                key={product.id}
                ref={(el) => (productRefs.current[index] = el)}
                className="slide-in flex flex-col opacity-0 transform translate-y-10 transition duration-500 ease-in-out"
              >
                <div className="relative flex flex-col mb-4 items-center justify-center rounded-lg shadow-lg transition duration-300 transform bg-customBlue text-white overflow-hidden cursor-pointer h-auto w-full">
                  <div className="md:p-6 p-2 text-center">
                    <h4 className="md:text-4xl text-xl uppercase font-semibold">
                      {product.name}
                    </h4>
                  </div>
                </div>

                <div className="mt-4 text-left uppercase bg-white text-black border border-gray-300 p-4 rounded-lg shadow-md w-auto h-auto">
                  {product.links.map((link, index) => (
                    <Link
                      key={index}
                      to={link.url}
                      className="mb-2 block"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div
                        className="font-bold hover:text-blue-500 transition duration-200"
                        style={{ color: link.bgColor }}
                      >
                        {link.title}
                      </div>
                      <div className="text-sm italic text-gray-600 hover:text-blue-400">
                        {link.theme}
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>
    </div>
  );
};

export default HomePage;
