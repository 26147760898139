import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import HomePage from "../Primex/Home";
import PrimeXSms from "../Primex/PrimexSms";
import PrimeXLms from "../Primex/PrimexLms";
import PrimeXMms from "../Primex/PrimexMms";
import PrimeXHms from "../Primex/PrimexHms";
import Casing_Capping16x25 from "../Primex/Casing_Capping16x25";
import Casing_Capping20x12 from "../Primex/Casing_Capping20x12";
import Casing_Capping32x16 from "../Primex/Casing_Capping32x16";
import BendsSms from "../Primex/BendsSms";
import BendsHMS from "../Primex/BendsHms";

const Primex = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* 2nd Level Routes under /prime-x */}
        <Route path="/pipes">
          <Route path="sms" element={<PrimeXSms />} />
          <Route path="lms" element={<PrimeXLms />} />
          <Route path="mms" element={<PrimeXMms />} />
          <Route path="hms" element={<PrimeXHms />} />
        </Route>
        <Route path="casing-capping">
          <Route path="16x25" element={<Casing_Capping16x25 />} />
          <Route path="20x12" element={<Casing_Capping20x12 />} />
          <Route path="32x16" element={<Casing_Capping32x16 />} />
        </Route>
        <Route path="bends">
          <Route path="sms" element={<BendsSms />} />
          <Route path="hms" element={<BendsHMS />} />
        </Route>
      </Routes>
      <Outlet /> {/* Renders nested routes */}
    </div>
  );
};

export default Primex;
