import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import BniProfile from "../assets/Loader/bni.png";

const BniPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleImageClick = () => {
    setIsOpen(true); // Open the full-screen view
  };

  const handleClose = () => {
    setIsOpen(false); // Close the full-screen view
  };

  return (
    <div className="min-h-screen my-10 flex items-center justify-center bg-white">
      {/* Centered BNI Profile Image */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeInOut" }}
        className="cursor-pointer"
        onClick={handleImageClick}
      >
        <img src={BniProfile} alt="BNI Profile" className="w-80 h-auto" />
      </motion.div>

      {/* Full-screen Overlay Image */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="fixed inset-0 z-50 bg-black bg-opacity-80 flex items-center justify-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={handleClose}
          >
            <motion.img
              src={BniProfile}
              alt="BNI Profile Expanded"
              className="max-w-full max-h-full"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
              transition={{ duration: 0.5 }}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default BniPage;
