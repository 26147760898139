import React from "react";
import Logo from "../assets/Loader/logo.png";

const ComingSoon = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 p-4">
      <h1 className="text-5xl font-bold text-blue-600 mb-4">Coming Soon</h1>
      <p className="text-xl text-gray-600 mb-6">
        We’re working hard to bring something amazing to you. Stay tuned!
      </p>
      <div className="relative mb-8">
        <img
          src={Logo} // Replace with your logo or relevant image
          alt="Coming Soon"
          className="w-48 h-auto mb-4 mx-auto"
        />
      </div>
      <p className="text-sm text-gray-500 mb-4">Subscribe for updates:</p>
      <form className="flex flex-col sm:flex-row">
        <input
          type="email"
          placeholder="Your email address"
          className="p-2 border border-gray-300 rounded mb-2 sm:mb-0 sm:mr-2"
          required
        />
        <button
          type="submit"
          className="bg-blue-600 text-white p-2 rounded hover:bg-blue-700 transition duration-200"
        >
          Notify Me
        </button>
      </form>
    </div>
  );
};

export default ComingSoon;
