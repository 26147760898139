import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/WEB PULSE/ALL LOGO/main logo.png";
import { motion } from "framer-motion";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const menuVariants = {
    open: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
    closed: {
      opacity: 0,
      y: "-100%",
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };

  const linkVariants = {
    hover: {
      scale: 1.1,
      color: "#3498db",
      transition: {
        type: "spring",
        stiffness: 300,
      },
    },
  };

  const hamburgerVariants = {
    open: { rotate: 45, y: 7 },
    closed: { rotate: 0, y: 0 },
  };

  const lineTwoVariants = {
    open: { opacity: 0 },
    closed: { opacity: 1 },
  };

  const lineThreeVariants = {
    open: { rotate: -45, y: -7 },
    closed: { rotate: 0, y: 0 },
  };

  return (
    <header className="bg-white border-b border-gray-200 p-3 max-w-7xl mx-auto relative">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <NavLink to="/" className="flex items-center">
          <motion.img
            src={logo}
            alt="Main Logo"
            className="h-[48px] mr-2"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          />
        </NavLink>

        {/* Hamburger Menu Button for small screens */}
        <button
          onClick={toggleMenu}
          className="text-black md:hidden focus:outline-none"
        >
          <motion.div animate={isOpen ? "open" : "closed"}>
            <motion.svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <motion.line
                x1="4"
                y1="6"
                x2="20"
                y2="6"
                strokeWidth="2"
                strokeLinecap="round"
                variants={hamburgerVariants}
                transition={{ duration: 0.3 }}
              />
              <motion.line
                x1="4"
                y1="12"
                x2="20"
                y2="12"
                strokeWidth="2"
                strokeLinecap="round"
                variants={lineTwoVariants}
                transition={{ duration: 0.3 }}
              />
              <motion.line
                x1="4"
                y1="18"
                x2="20"
                y2="18"
                strokeWidth="2"
                strokeLinecap="round"
                variants={lineThreeVariants}
                transition={{ duration: 0.3 }}
              />
            </motion.svg>
          </motion.div>
        </button>

        {/* Desktop Navigation Links */}
        <nav className="hidden md:flex md:items-center">
          <ul className="md:flex md:space-x-8 text-black uppercase tracking-wide">
            <motion.li
              className="relative group"
              variants={linkVariants}
              whileHover="hover"
            >
              <NavLink
                to="/wireshield"
                className="hover:text-gray-500 transition-colors border-b border-transparent hover:border-blue-500"
              >
                WireShield
              </NavLink>
              <span className="absolute left-0 bottom-0 w-full h-[2px] bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
            </motion.li>

            <motion.li
              className="relative group"
              variants={linkVariants}
              whileHover="hover"
            >
              <NavLink
                to="/primex"
                className="hover:text-gray-500 transition-colors border-b border-transparent hover:border-blue-500"
              >
                Primex
              </NavLink>
              <span className="absolute left-0 bottom-0 w-full h-[2px] bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
            </motion.li>

            <motion.li
              className="relative group"
              variants={linkVariants}
              whileHover="hover"
            >
              <NavLink
                to="/thunder"
                className="hover:text-gray-500 transition-colors border-b border-transparent hover:border-blue-500"
              >
                Thunder
              </NavLink>
              <span className="absolute left-0 bottom-0 w-full h-[2px] bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
            </motion.li>

            <motion.li
              className="relative group"
              variants={linkVariants}
              whileHover="hover"
            >
              <NavLink
                to="/costaarex"
                className="hover:text-gray-500 transition-colors border-b border-transparent hover:border-blue-500"
              >
                Costaarex
              </NavLink>
              <span className="absolute left-0 bottom-0 w-full h-[2px] bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
            </motion.li>

            <motion.li
              className="relative group"
              variants={linkVariants}
              whileHover="hover"
            >
              <NavLink
                to="/other-products"
                className="hover:text-gray-500 transition-colors border-b border-transparent hover:border-blue-500"
              >
                Other Products
              </NavLink>
              <span className="absolute left-0 bottom-0 w-full h-[2px] bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
            </motion.li>
          </ul>
        </nav>
      </div>

      {/* Mobile Navigation Links */}
      <motion.nav
        className={`md:hidden bg-white absolute top-full left-0 w-full shadow-lg z-10 ${
          isOpen ? "block" : "hidden"
        }`}
        animate={isOpen ? "open" : "closed"}
        variants={menuVariants}
        initial="closed"
      >
        <motion.ul className="flex flex-col text-black uppercase space-y-5 p-4">
          <motion.li
            className="border-b border-gray-200"
            variants={linkVariants}
            whileHover="hover"
            onClick={toggleMenu}
          >
            <NavLink
              to="/prime-x"
              className="block px-4 py-2 hover:text-gray-500 transition-colors"
            >
              Primex
            </NavLink>
          </motion.li>
          <motion.li
            className="border-b border-gray-200"
            variants={linkVariants}
            whileHover="hover"
            onClick={toggleMenu}
          >
            <NavLink
              to="/wireshield"
              className="block px-4 py-2 hover:text-gray-500 transition-colors"
            >
              WireShield
            </NavLink>
          </motion.li>

          <motion.li
            className="border-b border-gray-200"
            variants={linkVariants}
            whileHover="hover"
            onClick={toggleMenu}
          >
            <NavLink
              to="/costaa-rex"
              className="block px-4 py-2 hover:text-gray-500 transition-colors"
            >
              Costaarex
            </NavLink>
          </motion.li>
          <motion.li
            className="border-b border-gray-200"
            variants={linkVariants}
            whileHover="hover"
            onClick={toggleMenu}
          >
            <NavLink
              to="/thunder"
              className="block px-4 py-2 hover:text-gray-500 transition-colors"
            >
              Thunder
            </NavLink>
          </motion.li>
          <motion.li
            className="border-b border-gray-200"
            variants={linkVariants}
            whileHover="hover"
            onClick={toggleMenu}
          >
            <NavLink
              to="/other-products"
              className="block px-4 py-2 hover:text-gray-500 transition-colors"
            >
              Other Products
            </NavLink>
          </motion.li>
        </motion.ul>
      </motion.nav>
    </header>
  );
};

export default Header;
