import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Logo from "../assets/Loader/logo.png";

const Loader = ({ onFinishLoading }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Set a timer to end the loading after a specific time
    const timer = setTimeout(() => {
      setIsLoading(false);
      if (onFinishLoading) {
        onFinishLoading(); // Callback when loading is finished
      }
    }, 3000); // Loading duration: 3000ms or 3 seconds

    return () => clearTimeout(timer);
  }, [onFinishLoading]);

  if (!isLoading) {
    return null; // Hide the loader after time has passed
  }

  return (
    <div className="min-h-[80vh] max-h-screen flex flex-col justify-center bg-white my-10">
      {/* Centered and Enlarged Logo */}
      <div className="flex-grow flex items-center justify-center">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <img src={Logo} alt="Company Logo" className="w-64 h-auto" />
        </motion.div>
      </div>
    </div>
  );
};

export default Loader;
