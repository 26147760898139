// Routes/ConstaaRex.js
import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import HomePage from "../ConstaaRex/Home";
import ConstraXSms from "../ConstaaRex/ConstraSms";
import ConstraXLms from "../ConstaaRex/ConstraLms";
import ConstraXMms from "../ConstaaRex/ConstraMms";
import ConstraXHms from "../ConstaaRex/ConstraHms";

const ConstaaRex = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* Nested routes for /constra-x paths */}
        <Route path="pipes">
          <Route path="sms" element={<ConstraXSms />} />
          <Route path="lms" element={<ConstraXLms />} />
          <Route path="mms" element={<ConstraXMms />} />
          <Route path="hms" element={<ConstraXHms />} />
        </Route>
      </Routes>
      <Outlet /> {/* Renders nested routes */}
    </div>
  );
};

export default ConstaaRex;
