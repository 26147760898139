// Routes/Thunder.js
import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import HomePage from "../Thunder/Home"; // Ensure correct path based on your structure
import ThunderSms from "../Thunder/ThunderSms";
import ThunderLms from "../Thunder/ThunderLms";
import ThunderMms from "../Thunder/ThunderMms";
import ThunderHms from "../Thunder/ThunderHms";

const Thunder = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* Nested routes for /thunder-x paths */}
        <Route path="pipes">
          <Route path="sms" element={<ThunderSms />} />
          <Route path="lms" element={<ThunderLms />} />
          <Route path="mms" element={<ThunderMms />} />
          <Route path="hms" element={<ThunderHms />} />
        </Route>
      </Routes>
      <Outlet /> {/* Renders nested routes */}
    </div>
  );
};

export default Thunder;
