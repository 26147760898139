// Routes/WireShield.js
import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import HomePage from "../WireShield/Home"; // Ensure this path is correct
import WireShieldSms from "../WireShield/WireShieldSms";
import WireShieldLms from "../WireShield/WireShieldLms";
import WireShieldMms from "../WireShield/WireShieldMms";
import WireShieldHms from "../WireShield/WireShieldHms";
import Casing_Capping16x25 from "../WireShield/Casing_Capping16x25";
import Casing_Capping20x12 from "../WireShield/Casing_Capping20x12";
import Casing_Capping32x16 from "../WireShield/Casing_Capping32x16";
import Casing_Capping30x15 from "../WireShield/Casing_Capping30x15";
import Casing_Capping40x20 from "../WireShield/Casing_Capping40X20";
import Casing_Capping50x20 from "../WireShield/Casing_Capping50X20";
import BendsMms from "../WireShield/BendsMms";
import BendsHMS from "../WireShield/BendsHms";

const WireShield = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* Nested routes for /wire-shield-x paths */}
        <Route path="pipes">
          <Route path="sms" element={<WireShieldSms />} />
          <Route path="lms" element={<WireShieldLms />} />
          <Route path="mms" element={<WireShieldMms />} />
          <Route path="hms" element={<WireShieldHms />} />
        </Route>
        <Route path="casing-capping">
          <Route path="16x25" element={<Casing_Capping16x25 />} />
          <Route path="20x12" element={<Casing_Capping20x12 />} />
          <Route path="32x16" element={<Casing_Capping32x16 />} />
          <Route path="30x15" element={<Casing_Capping30x15 />} />
          <Route path="40x20" element={<Casing_Capping40x20 />} />
          <Route path="50x20" element={<Casing_Capping50x20 />} />
        </Route>
        <Route path="bends">
          <Route path="mms" element={<BendsMms />} />
          <Route path="hms" element={<BendsHMS />} />
        </Route>
      </Routes>
      <Outlet /> {/* Renders nested routes */}
    </div>
  );
};

export default WireShield;
