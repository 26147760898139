import { Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./Components/Header";
import HomePage from "./Primex/Home";

import axios from "axios";

import BendsHMS from "./Primex/BendsHms";
import Footer from "./Components/Footer";
import Loader from "./Components/Loader";
import ComingSoon from "./Extra/ComingSoon";
import BniPage from "./Extra/BniPage";
import { useEffect, useState } from "react";
import Primex from "./Routes/Primex";
import ConstaaRex from "./Routes/ConstaaRex";
import Thunder from "./Routes/Thunder";
import WireShield from "./Routes/WireShield";
import Other_Products from "./Routes/Other_Products";
import { FaHome } from "react-icons/fa";
import WhatsAppChat from "./Components/WhatsAppChat";

function App() {
  const [loading, setLoading] = useState(true);
  const [backendLoaded, setBackendLoaded] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    const checkBackendConnection = async () => {
      try {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/all/`);
        setBackendLoaded(true);
      } catch (error) {
        console.error("Error connecting to the backend:", error);
      }
    };

    checkBackendConnection();
  }, []);

  // Show the loader for at least 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // 3 seconds

    return () => clearTimeout(timer);
  }, []);

  // Optionally set videoLoaded to true after your video loading logic
  useEffect(() => {
    if (videoLoaded) {
      setLoading(false); // This will stop the loader when videos are loaded
    }
  }, [videoLoaded]);

  return (
    <div className="App">
      {loading || !backendLoaded ? (
        <Loader />
      ) : (
        <div className="App">
          <Header />

          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/bni" element={<BniPage />} />X
            <Route path="/coming-soon" element={<ComingSoon />} />X
            <Route path="/prime-x/*" element={<Primex />} />
            <Route path="/costaa-rex/*" element={<ConstaaRex />} />
            <Route path="/thunder/*" element={<Thunder />} />
            <Route path="/wireshield/*" element={<WireShield />} />
            <Route path="/other-products/*" element={<Other_Products />} />
          </Routes>

          <Footer />

          <div
            className="fixed bottom-4 right-4 bg-gradient-to-r from-blue-500 to-purple-600 text-white p-2 rounded-full shadow-2xl transition-all duration-300 flex items-center justify-center z-50 transform hover:scale-110 hover:shadow-inner hover:from-blue-600 hover:to-purple-700"
            onClick={() => {
              window.location.href = "/";
            }}
            style={{
              boxShadow:
                "0 8px 15px rgba(0, 0, 255, 0.3), inset 0 -3px 8px rgba(255, 255, 255, 0.5)",
              filter: "drop-shadow(0px 4px 6px rgba(0, 0, 255, 0.3))",
              border: "1px solid rgba(255, 255, 255, 0.2)",
              backdropFilter: "blur(5px)",
            }}
          >
            <FaHome size={30} />
          </div>

          <WhatsAppChat />
        </div>
      )}
    </div>
  );
}

export default App;
