import React from "react";

const Marquee = () => {
  return (
    <div className="marquee-container">
      <div className="marquee">
        <span id="award" className="marquee-content">
          Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme
          Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme
          Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
        </span>
        <span id="award" className="marquee-content">
          Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme
          Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme
          Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
          &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp; Wellme Pipes &nbsp;
        </span>
      </div>
    </div>
  );
};

export default Marquee;
