import React, { useRef, useState } from "react";
import { FaVolumeMute, FaVolumeUp } from "react-icons/fa";

const Carousel = ({ videoUrl }) => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true); // State to track if the video is muted

  // Function to toggle video sound
  const toggleSound = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted; // Toggle muted property
      setIsMuted(!isMuted); // Update state
    }
  };

  return (
    <div className="relative">
      <video
        ref={videoRef}
        className="md:object-center w-full h-auto"
        loop
        autoPlay
        muted // Start muted
        src={videoUrl}
      />

      {/* Sound Control Button */}
      <div className="absolute bottom-4 right-2">
        <button
          onClick={toggleSound}
          className="p-2 bg-transparent rounded-full"
        >
          {isMuted ? (
            <FaVolumeMute size={24} className="text-black" />
          ) : (
            <FaVolumeUp size={24} className="text-black" />
          )}
        </button>
      </div>
    </div>
  );
};

export default Carousel;
