// Routes/Other_Products.js
import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import HomePage from "../Other_Products/Home"; // Ensure this path is correct
import DeepJunction from "../Other_Products/DeepJunction";
import CableClips from "../Other_Products/CableClips";
import DoubleNailClamp from "../Other_Products/DoubleNailClamp";
import Fanbox from "../Other_Products/Fanbox";
import FanSheet from "../Other_Products/FanSheet";
import Gitti from "../Other_Products/Gitti";
import JunctionBox from "../Other_Products/JunctionBox";
import RoundSheet from "../Other_Products/RoundSheet";
import SquareBox from "../Other_Products/SquareBox";
import Tape from "../Other_Products/Tape";

const Other_Products = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="deep-junction" element={<DeepJunction />} />
        <Route path="cable-clips" element={<CableClips />} />
        <Route path="double-nail-clamp" element={<DoubleNailClamp />} />
        <Route path="fanbox" element={<Fanbox />} />
        <Route path="fan-sheet" element={<FanSheet />} />
        <Route path="gitti" element={<Gitti />} />
        <Route path="junction-box" element={<JunctionBox />} />
        <Route path="round-sheet" element={<RoundSheet />} />
        <Route path="square-box" element={<SquareBox />} />
        <Route path="tape" element={<Tape />} />
      </Routes>
      <Outlet />
    </div>
  );
};

export default Other_Products;
