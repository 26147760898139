import React from "react";

const About = () => {
  return (
    <div>
      <h2 id="award" className="text-sm mb-3 ">
        About Wellme Pipes
      </h2>
      <p className=" text-sm">
        At Wellme Pipes, we are dedicated to delivering high-quality, durable
        piping solutions that meet the needs of various industries. With a
        commitment to innovation, reliability, and customer satisfaction, our
        products are crafted to the highest standards, ensuring optimal
        performance and longevity. We understand the importance of quality in
        every project, which is why Wellme Pipes is a trusted choice for clients
        seeking exceptional piping solutions. Explore our range and discover why
        Wellme Pipes is a leader in piping technology.
      </p>
    </div>
  );
};

export default About;
