import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Marquee from "../Marquee/Marquee";

const Social = () => {
  const socialMediaIcons = [
    {
      imgSrc:
        "https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png",
      label: "Instagram",
      link: "https://www.instagram.com/wellmepipes/",
    },
    {
      imgSrc:
        "https://logos-world.net/wp-content/uploads/2020/04/YouTube-Symbol.png",
      label: "YouTube",
      link: "https://www.youtube.com/channel/UCQx5eQXPZXsvYDIWpXhtpwg",
    },
    {
      imgSrc:
        "https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg",
      label: "Facebook",
      link: "https://www.facebook.com/WellmePipesPvtLtd/",
    },
    {
      imgSrc:
        "https://i.pinimg.com/originals/65/25/ea/6525ea3430a2145e472ce030dd98bdcb.png",
      label: "Snapchat",
      link: "https://www.snapchat.com/add/wellmepipes?share_id=cZbtO4yI4xw&locale=en-GB",
    },
    {
      imgSrc:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRokEYt0yyh6uNDKL8uksVLlhZ35laKNQgZ9g&s",
      label: "LinkedIn",
      link: "https://www.linkedin.com/posts/wellme-pipes-pvt-ltd_wellmepipes-coldresistant-pvc-activity-7250132289618149376-FmhH",
    },
    {
      imgSrc:
        "https://img.freepik.com/premium-photo/twitter-logo-icon-illustration-vector_895118-5895.jpg",
      label: "Twitter",
      link: "https://x.com/WellmePipes",
    },
  ];

  const additionalServices = [
    {
      imgSrc:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/768px-Google_%22G%22_logo.svg.png",
      label: "Google",
      link: "https://g.co/kgs/rfuXLMv",
    },
    {
      imgSrc:
        "https://cdn0.iconfinder.com/data/icons/most-usable-logos/120/Amazon-512.png",
      label: "Amazon",
      link: "https://www.amazon.in/s?k=WELLME.PIPES",
    },
    {
      imgSrc:
        "https://seeklogo.com/images/F/flipkart-logo-C9E637A758-seeklogo.com.png",
      label: "Flipkart",
      link: "/coming-soon",
    },
    {
      imgSrc: "https://i.imgur.com/dW8DrvZ.png",
      label: "Gem Portal",
      link: "/coming-soon",
    },
    {
      imgSrc: "https://i.imgur.com/ZrB67Iz.png",
      label: "BNI",
      link: "/bni",
    },
    {
      imgSrc: "https://m.media-amazon.com/images/I/71IxKvGqiWL.png",
      label: "Alibaba",
      link: "/coming-soon",
    },
  ];

  // Combine both arrays
  const combinedIcons = [...socialMediaIcons, ...additionalServices];

  // Render items for combined array
  const renderItems = combinedIcons.map((item, index) => (
    <a
      key={index}
      href={item.link}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={`Visit ${item.label}`}
      className="focus:outline-none mx-4"
    >
      <img
        src={item.imgSrc}
        alt={item.label}
        className="md:h-20 h-12 w-auto mx-auto"
      />
    </a>
  ));

  return (
    <div className="w-full bg-white flex flex-col items-center">
      <Marquee />
      <AliceCarousel
        autoPlay
        infinite
        animationDuration={1000} // Custom animation duration
        autoPlayInterval={500}
        disableButtonsControls
        disableDotsControls
        items={renderItems}
        responsive={{
          0: { items: 4 },
          600: { items: 4 },
          1024: { items: 6 },
        }}
        mouseTracking
        stagePadding={0}
        // Custom left-to-right slide effect
        direction="ltr" // <-- Left to Right
      />
      <Marquee />
    </div>
  );
};

export default Social;
