import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const YouTubeVideoCarousel = ({ ytLinks = [] }) => {
  const sliderRef = useRef(null);

  // Check if ytLinks is valid and has at least one entry
  if (!Array.isArray(ytLinks) || ytLinks.length === 0) {
    console.warn("No YouTube links provided or ytLinks is not an array");
    return null;
  }

  // Settings for the react-slick carousel
  const settings = {
    infinite: ytLinks.length > 1,
    speed: 500,
    slidesToShow: ytLinks.length > 1 ? 2 : 1,
    slidesToScroll: 1,
    autoplay: ytLinks.length > 1,
    autoplaySpeed: 4000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: ytLinks.length > 1 ? 2 : 1 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 1 },
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  // Convert YouTube URL to embed format
  const getEmbedUrl = (ytLink) => {
    if (!ytLink) return "";
    if (ytLink.includes("youtube.com/watch?v=")) {
      return ytLink.replace("watch?v=", "embed/");
    } else if (ytLink.includes("youtu.be/")) {
      return ytLink.replace("youtu.be/", "www.youtube.com/embed/");
    }
    return ytLink;
  };

  return (
    <div className="mt-12">
      <h2
        id="award"
        className="md:text-xl text-sm font-bold text-center w-full mb-2 text-black"
      >
        Product Videos
      </h2>
      <div className="w-full overflow-hidden relative">
        <Slider ref={sliderRef} {...settings}>
          {ytLinks.map((link, index) => (
            <div key={index} className="p-2">
              <div className="relative pb-[56.25%] h-0 overflow-hidden rounded-md">
                <iframe
                  src={getEmbedUrl(link)}
                  title={`YouTube Video ${index + 1}`}
                  frameBorder="0"
                  allowFullScreen
                  className="absolute top-0 left-0 w-full h-full"
                ></iframe>
              </div>
            </div>
          ))}
        </Slider>

        {/* Navigation Buttons */}
        {ytLinks.length > 1 && (
          <div className="flex justify-between w-full mt-4">
            <button
              onClick={() => sliderRef.current.slickPrev()}
              className="px-4 py-2 bg-customBlue text-white rounded-md hover:bg-blue-600"
            >
              Previous
            </button>
            <button
              onClick={() => sliderRef.current.slickNext()}
              className="px-4 py-2 bg-customBlue text-white rounded-md hover:bg-blue-600"
            >
              Next
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default YouTubeVideoCarousel;
