import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./fonts.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

const RootComponent = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if the screen width is less than a typical mobile threshold (e.g., 768px)
    const checkMobile = () => setIsMobile(window.innerWidth <= 768);

    // Initial check
    checkMobile();

    // Listen for resize events
    window.addEventListener("resize", checkMobile);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  return (
    <BrowserRouter>
      {isMobile ? (
        <App />
      ) : (
        <div className="desktop-message flex  justify-center items-center h-screen flex-col">
          <h1>This site is mobile-based.</h1>
          <p>
            Please view this site on a mobile device for the best experience.
          </p>
        </div>
      )}
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RootComponent />);
