import React from "react";
import {
  FaEnvelope,
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
  FaYoutube,
  FaSnapchat,
  FaTwitter,
  FaChartLine,
} from "react-icons/fa";
import Contact from "../assets/Animation/contact.gif";
import Social from "../Components/Social";
import Marquee from "../Marquee/Marquee";

const Footer = () => {
  return (
    <div className="relative bg-white text-gray-400 font-sans md:p-3 p-0">
      <Social />

      <br />

      <h2
        id="award"
        className="md:text-2xl md:text-[15px] text-[12px] font-bold text-center text-customBlue"
      >
        WELLME PIPES PRODUCTS ARE <br /> MADE IN INDIA
      </h2>
      <div className="border-b border-dotted border-gray-400 my-4"></div>

      <div className="mb-5 flex justify-center items-center max-w-[95%] mx-auto flex-col">
        <div className="flex flex-col justify-center items-center md:space-x-5 space-y-2">
          <div className="flex flex-col">
            <p
              id="award"
              className="my-2 uppercase font-semibold text-[9px] md:text-base text-gray-600"
            >
              Manufacturing By:
            </p>
            <p className="text-gray-400 text-sm md:text-base">
              WELLME PIPES PVT. LTD. <br />
              Khewat No. 213, Khatta No. 252, Killa No. 21 121, <br />
              Bhakharpur Road, Ganaur, Bhakharpur, Sonipat, <br />
              Haryana, 131101 <br />
              Customer Care:{" "}
              <a href="tel: 9855955985" className="text-gray-400 no-underline">
                98-559-559-85
              </a>{" "}
              <br />
              or Email Us At -{" "}
              <a
                href="mailto:wellmepipes@gmail.com"
                className="text-gray-400 no-underline"
              >
                wellmepipes@gmail.com
              </a>
            </p>
          </div>

          <div className="flex flex-col">
            <p
              id="award"
              className="my-2 font-semibold uppercase text-[9px] md:text-base text-gray-600"
            >
              Marketing By:
            </p>
            <p className="text-gray-400 text-sm md:text-base">
              WELLME GROUPS <br />
              Khewat No. 213, Khatta No. 252, Killa No. 21 12 1, <br />
              Bhakharpur Road, Ganaur, Bhakharpur, Sonipat, <br />
              Haryana, 131101 <br />
              Customer Care:{" "}
              <a href="tel: 9910960448" className="text-gray-400 no-underline">
                99-109-60-448
              </a>
              ,{" "}
              <a href="tel: 9910960259" className="text-gray-400 no-underline">
                99-109-60-259
              </a>{" "}
              <br />
              or Email Us At -{" "}
              <a
                href="mailto:wellmegroups@gmail.com"
                className="text-gray-400 no-underline"
              >
                wellmegroups@gmail.com
              </a>
            </p>
          </div>

          <div className="flex flex-col">
            <p
              id="award"
              className="my-2 font-semibold text-[9px] md:text-base text-gray-600"
            >
              CUSTOMER SUPPORT :
            </p>
            <p className="text-gray-400 text-sm md:text-base">
              ANJANI SUT ENTERPRISES <br />
              Office No: 175A QU-Block Ground Floor <br />
              Pitampura, Rohini, Delhi-110034 <br />
              Customer Care:{" "}
              <a href="tel: 9589586513" className="text-gray-400 no-underline">
                958-958-6513,{" "}
              </a>
              <a href="tel: 9589587134" className="text-gray-400 no-underline">
                958-958-7134,{" "}
              </a>
              <br />
              <a href="tel: 9589587215" className="text-gray-400 no-underline">
                958-958-7215,{" "}
              </a>
              <a href="tel: 7710110909" className="text-gray-400 no-underline">
                77-101-101-09,{" "}
              </a>
              <a href="tel: 7710110908" className="text-gray-400 no-underline">
                77-101-101-08,{" "}
              </a>{" "}
              <br />
              or Email Us At -
              <a
                href="mailto:anjanisutenterprisess@gmail.com"
                className="text-gray-400 no-underline"
              >
                anjanisutenterprisess@gmail.com
              </a>{" "}
              <br />
            </p>
          </div>
        </div>
      </div>

      <div className="flex justify-between border-t border-b border-black">
        <div className="flex flex-row my-5 justify-around text-left md:text-right w-full mx-auto">
          <a
            href="https://wellmepipes.com/"
            id="award"
            target="_blank"
            className="md:text-2xl flex flex-row justify-center items-center hover:scale-105 ease-in-out duration-300 hover:text-[#7e7e7e] text-[10px] font-bold text-center text-customBlue"
          >
            wellmepipes
            <span className="font-mono">.</span>com
          </a>

          <a
            href="https://wellmepipe.com/"
            id="award"
            target="_blank"
            className="md:text-2xl text-customBlue text-[10px] hover:scale-105 ease-in-out duration-300 hover:text-[#7e7e7e] font-bold text-center"
          >
            wellmepipe
            <span className="font-mono">.</span>com
          </a>
        </div>
      </div>

      <div className="md:mt-10 flex justify-center items-center py-3 border-t border-gray-400 w-full">
        <p className="text-xs md:text-sm text-gray-400">
          &copy; 2024 <span className=" text-customBlue">WELLME PIPES</span> All
          Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
